import React from 'react';
import Button from 'components/global/Buttons/Button';
import { useAssessmentContext } from 'services/context/assessmentContext/useAssessmentContext';
import { FlexContainer } from 'components/local/styles/StyledComponents';
import {
  NavWrapper,
  QuestionCount
} from 'components/local/navbar/styles/StyledComponents';
import { getSubmitButtonText } from 'containers/app/helpers/getSubmitButtonText';
import { BUTTON_TEXTS } from 'containers/app/constants';
import { shouldSubmitAssessment } from 'containers/app/helpers/shouldSubmitAssessment';

const ClickableBottomNavBar = ({ formikProps, setSlot, setModal }) => {
  const { state, currentSection, currentSlot } = useAssessmentContext();
  const {
    currentSlotIndex,
    currentSlotId,
    slotContentIsLoading,
    assessmentAttempt,
    files
  } = state;
  const {
    assessment: { sections: totalSections }
  } = assessmentAttempt;
  const slotNumber = currentSlotIndex + 1;
  const sectionLength = currentSection.slot_attempts.length;

  const handleMoveSlot = direction => {
    formikProps.submitForm();
    setSlot(currentSlotIndex + direction);
  };

  const handleFinishSection = () => {
    setSlot(null);
    setModal(true);
  };

  const isAssessmentReadyToSubmit = shouldSubmitAssessment(
    totalSections.length,
    currentSection.sort_index,
    sectionLength,
    currentSlotIndex
  );

  return (
    <NavWrapper>
      <FlexContainer
        width="100%"
        justifyContent={currentSlotIndex > 0 ? 'space-between' : 'flex-end'}
        maxWidth="1024px"
      >
        {currentSlotIndex > 0 && (
          <Button
            className="secondary nav"
            text="PREVIOUS"
            disabled={slotContentIsLoading}
            onClick={() => handleMoveSlot(-1)}
          ></Button>
        )}
        <QuestionCount>
          {slotNumber} of {sectionLength}
        </QuestionCount>
        <Button
          disabled={
            slotContentIsLoading ||
            !!formikProps.errors[currentSlotId] ||
            (currentSlot?.question?.accept_types?.includes('video') &&
              !files[currentSlot?.id])
          }
          className="nav"
          text={
            slotNumber === sectionLength
              ? getSubmitButtonText(!isAssessmentReadyToSubmit, true)
              : BUTTON_TEXTS.NEXT
          }
          onClick={
            slotNumber === sectionLength
              ? handleFinishSection
              : () => handleMoveSlot(1)
          }
        ></Button>
      </FlexContainer>
    </NavWrapper>
  );
};

export default ClickableBottomNavBar;
