import { useEffect, useRef, useState } from 'react';

export const useMediaStream = () => {
  const [stream, setStream] = useState(null);
  const [error, setError] = useState(null);
  const streamRef = useRef(null);

  useEffect(() => {
    const initializeMedia = async () => {
      try {
        // Check if the media devices are supported
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          throw new Error('Media devices not supported');
        }

        // Check if any video input devices are available
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          device => device.kind === 'videoinput'
        );
        if (videoDevices.length === 0) {
          throw new Error('No video input devices found');
        }

        // Attempt to get the media stream
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: {
              max: 640,
              ideal: 640,
              min: 320
            },
            height: {
              max: 480,
              ideal: 480,
              min: 240
            }
          },
          frameRate: 15,
          audio: true
        });
        streamRef.current = mediaStream;
        setStream(mediaStream);
        setError(null);
      } catch (err) {
        console.error('Error accessing media devices:', err);
        if (
          err.name === 'NotFoundError' ||
          err.name === 'DevicesNotFoundError'
        ) {
          setError(
            'No camera or microphone found. Please check your device connections.'
          );
        } else if (
          err.name === 'NotAllowedError' ||
          err.name === 'PermissionDeniedError'
        ) {
          setError(
            'Permission to access camera and microphone was denied. Please allow access in your browser settings.'
          );
        } else {
          setError(`Unable to access camera or microphone: ${err.message}`);
        }
      }
    };

    initializeMedia();

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  return { stream, error };
};

export const useAudioAnalyser = stream => {
  const [micLevel, setMicLevel] = useState(0);
  const analyserRef = useRef(null);
  const rafRef = useRef(null);

  useEffect(() => {
    if (!stream) return;

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);
    microphone.connect(analyser);
    analyser.fftSize = 256;
    analyserRef.current = analyser;

    const updateMicLevel = () => {
      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(dataArray);
      const average =
        dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
      const newMicLevel = average / 128; // Normalize to 0-1 range

      // Only update state if the change is significant
      setMicLevel(newMicLevel);

      rafRef.current = requestAnimationFrame(updateMicLevel);
    };

    updateMicLevel();

    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
      if (audioContext.state !== 'closed') {
        audioContext.close();
      }
    };
  }, [stream]);

  return micLevel;
};
