import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useAssesmentContext } from 'services/context/assessmentContext/assessmentContext';
import { microphone } from 'components/global/assets/microphone';
import RecordRTC from 'recordrtc';
import Button from 'components/global/Buttons/Button';
import styled, { keyframes } from 'styled-components';
import { VIDEO_TIME_LIMIT } from 'utils/constants/constants.js';
import {
  useAudioAnalyser,
  useMediaStream
} from 'components/global/videoUpload/hooksVideoUpload';

export const VideoUploadInput = ({ label, formikProps, name }) => {
  const { dispatch } = useAssesmentContext();

  const { stream, error } = useMediaStream();
  const micLevel = useAudioAnalyser(stream);

  const [recording, setRecording] = useState(false);
  const [videoBlob, setVideoBlob] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const previewRef = useRef(null);
  const videoRef = useRef(null);
  const recorderRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    if (stream && previewRef.current) {
      previewRef.current.srcObject = stream;
    }
  }, [stream]);

  const startRecording = () => {
    recorderRef.current = new RecordRTC(stream, {
      type: 'video',
      mimeType: 'video/webm;codecs=vp8,opus',
      canvas: {
        width: 640,
        height: 480
      },
      frameInterval: 15,
      frameRate: 15,
      numberOfAudioChannels: 1,
      audioBitsPerSecond: 25000,
      videoBitsPerSecond: 200000,
      videoConstraints: {
        width: { max: 640 },
        height: { max: 480 },
        frameRate: 15,
        facingMode: 'user',
        bitrate: 200000
      }
    });
    recorderRef.current.startRecording();
    setRecording(true);
    setRecordingTime(0);
    videoRef.current.srcObject = stream;
  };

  const stopRecording = useCallback(() => {
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        setVideoBlob(blob);
        setRecording(false);
        if (videoRef.current) {
          videoRef.current.srcObject = null;
          videoRef.current.src = URL.createObjectURL(blob);

          let file = new File([blob], name);
          formikProps.setFieldValue(name, file, true);
          dispatch({
            type: 'SET_FILE',
            payload: {}
          });
          dispatch({
            type: 'SET_FILE',
            payload: { [file.name]: file }
          });
        }
      });
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
  }, [recorderRef, formikProps, dispatch, name]);

  const startDeletion = () => {
    setIsDeleting(true);
  };

  const cancelDelete = () => {
    setIsDeleting(false);
  };

  const deleteVideo = () => {
    videoRef.current.srcObject = null;
    videoRef.current.src = '';
    setVideoBlob(null);
    setIsDeleting(false);
    setRecordingTime(0);
    // Set field value to null, for some reason, this doesn't work as expected
    formikProps.resetForm({
      ...formikProps.values,
      [name]: null
    });
    formikProps.setFieldValue(name, null, true);

    dispatch({
      type: 'SET_FILE',
      payload: {}
    });
  };

  useEffect(() => {
    if (recording && recordingTime < VIDEO_TIME_LIMIT) {
      timerRef.current = setInterval(() => {
        setRecordingTime(prevTime => {
          if (prevTime + 1 >= VIDEO_TIME_LIMIT) {
            stopRecording();
            return VIDEO_TIME_LIMIT;
          }
          return prevTime + 1;
        });
      }, 1000);
    }
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [recording, recordingTime, stopRecording]);

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const remainingTime = VIDEO_TIME_LIMIT - recordingTime;

  return (
    <Container>
      {label && <h2>{label}</h2>}

      {/* Actions */}
      <HeaderContainer>
        <TimerDisplay>
          {recording || videoBlob
            ? `Time Remaining: ${formatTime(remainingTime)}`
            : `Time Remaining: ${formatTime(remainingTime)}`}
        </TimerDisplay>
      </HeaderContainer>
      <VideoContainer>
        {/* Videos */}
        <div>
          {/* When we are not recording or have a video blob show the preview*/}
          {recording && (
            <RecordingIndicator>
              <svg height="100" width="100">
                <circle cx="50" cy="50" r="10" fill="red" />
              </svg>
            </RecordingIndicator>
          )}

          <PreviewVideo
            ref={previewRef}
            width="640"
            height="480"
            autoPlay
            muted
            playsInline
            recording={recording}
            videoBlob={videoBlob}
          />
          <RecordedVideo
            ref={videoRef}
            width="640"
            height="480"
            autoPlay
            muted={recording}
            playsInline
            controls={!!videoBlob}
            recording={recording}
            videoBlob={videoBlob}
          />
        </div>
        {/* Microphone indicator*/}
        <MicrophoneContainer videoBlob={videoBlob}>
          <MicrophoneMeter>
            <MicrophoneLevel micLevel={micLevel} />
          </MicrophoneMeter>
          <MicrophoneIcon>{microphone}</MicrophoneIcon>
        </MicrophoneContainer>
      </VideoContainer>
      <ActionsContainer>
        {!recording && !videoBlob && (
          <Button text="Start recording" onClick={startRecording} />
        )}
        {recording && <Button text="Stop recording" onClick={stopRecording} />}
        {videoBlob && !isDeleting && (
          <Button
            text="Retake recording"
            onClick={startDeletion}
            className="warning"
          />
        )}
        {videoBlob && isDeleting && (
          <>
            <h3>Are you sure you want to retake video?</h3>
            <ButtonsContainer>
              <Button
                text="No, use previous recording"
                onClick={cancelDelete}
              />
              <Button
                text="Yes, retake video"
                onClick={deleteVideo}
                className="warning"
              />
            </ButtonsContainer>
          </>
        )}
      </ActionsContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
`;
const ActionsContainer = styled.div`
  display: flex;
  margin: 16px 0px;
  gap: 8px;
  flex-direction: column;
`;
const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PreviewVideo = styled.video`
  display: ${props => (props.recording || props.videoBlob ? 'none' : 'block')};
`;

const RecordedVideo = styled.video`
  display: ${props => (props.recording || props.videoBlob ? 'block' : 'none')};
  -webkit-media-controls-panel {
    display: flex !important;
    opacity: 1 !important;
  }
`;

const MicrophoneContainer = styled.div.attrs(props => ({
  style: {
    visibility: props.videoBlob ? 'hidden' : 'visible'
  }
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 220px;
`;

const MicrophoneMeter = styled.div`
  width: 10px;
  height: 200px;
  background-color: #ddd;
  position: relative;
  overflow: hidden;
`;

const MicrophoneLevel = styled.div.attrs(props => ({
  style: {
    height: `${props.micLevel * 100}%`
  }
}))`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: green;
  transition: height 0.01s;
`;

const MicrophoneIcon = styled.div`
  margin-top: 5px;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const blink = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const RecordingIndicator = styled.div`
  position: absolute;
  animation: ${blink} 1.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
`;

const TimerDisplay = styled.div`
  font-size: 18px;
  font-weight: bold;
  align-self: center;
`;
